.navbar-background {
  background-color: #013B8D !important;
}

.navbar-toggler{
  border: none !important;
}

.navbar-toggler:focus{
  box-shadow: none;
}

.navbar-toggler-icon {
  background-image: url("https://bluehawks.s3.us-east-2.amazonaws.com/toggleButton+(1).webp") !important;
}

.nav-link{
  text-transform: uppercase;
  font-size: 12pt;
  color: #FFD37A;
  position: relative;
  text-decoration: none;
}

.navbar-brand-logo{
  max-width: 215px;
}

.navbar-brand {
  width: auto;
  display: flex;
  align-items: center;
  margin-right: auto;
}

.ml-auto {
  margin-left: auto;
}

.nav-link::after {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 0;
  right: 0;
  width: 100%;
  height: 2px;
  background-color: #fff;
  transform: scaleX(0);
  transition: transform 0.3s;
}

.nav-link:hover{
  color: white;
}

.nav-link:hover::after {
  transform: scaleX(0.5);
}

@media screen and (max-width: 978px){
  .navbar-brand-logo{
    width: 200px;
  }
}

@media screen and (max-width: 768px){
  .nav-link{
    border: solid 1px #FFD37A;
      border-left: none;
      border-right: none;
      border-top: none;
  }
  .navbar-brand-logo{
    width: 190px;
  }
}

@media screen and (max-width: 385px){
  .navbar-brand-logo{
    width: 120px;
  }
  .navbar-brand{
    width: 50%;
  }
}
