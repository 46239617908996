.title-footer{
  font-family: 'Montserrat', sans-serif;
  color: white;
  font-weight: 900;
  font-size: 18pt;
  margin: 20px 0;
}

.body-footer{
  color: white;
  font-family: 'Montserrat', sans-serif;
  font-weight: 900;
  padding: 0px;
  margin-bottom: 10px;
}

.body-footer a{
  text-decoration: none;
  color: white;
  font-weight: 900;
  position: relative;
}

.body-footer a::after{
  content: "";
  position: absolute;
  bottom: -4px;
  left: 0;
  right: 0;
  width: 100%;
  height: 2px;
  background-color: #fff;
  transform: scaleX(0);
  transition: transform 0.3s;
}

.MainTitle-footer{
  font-family: sans-serif;
  font-size: 40px;
  color: #FAFF00;
  font-weight: 600;
}
.body-footer a:hover{
  color: white;
}

.body-footer a:hover::after {
  transform: scaleX(1);
}

footer{
  background-color: #013B8D;
}

.media-icon{
  transition: transform .20s,-webkit-transform .20s;
}

.media-icon:hover{
  -webkit-transform: translateY(-6px);
  transform: translateY(-6px);
}


@media screen and (max-width: 768px){
  .body-footer{
    font-size: 10pt;
  }

  .title-footer{
    font-size: 14pt;
  }
}

@media screen and (max-width: 385px){
  .body-footer{
    font-size: 7.5pt;
  }
  
  .title-footer{
    font-size: 10pt;
  }

  .Copyright{
    font-size: 8pt;
  }
}

@media screen and (max-width: 300px){
  .body-footer{
    font-size: 7pt;
  }
  
  .title-footer{
    font-size: 8pt;
  }
}
