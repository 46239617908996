.bannerServices1{
  background-color: #013B8D;
}

.titleBannerServices1{
  color: white;
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
  font-size: 28pt;
}

.titleBannerServices2{
  color: #C1A875;
  font-size: 67pt;
  font-weight: bold;
}

.bannerServices2{
  background-image: url("https://bluehawks.s3.us-east-2.amazonaws.com/backgroundBanner2Services.webp");
}

.card{
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

/*============Banner 2==============*/
.title-bannerServices2 h6{
  color: #013B8D;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 25pt;
}

.card-title-services{
  font-family: 'Montserrat', sans-serif;
  font-weight: 900;
}

.btn-hero-services{
  border: 2px solid #013B8D;
  border-radius: 20px;
  color: #013B8D;
  background-color: transparent;
  font-family: 'Montserrat', sans-serif;
  padding: 10px 15px;
  font-weight: 900;
  text-decoration: none;
}

.btn-right-slide-services-mobile{
  border: none;
  border-radius: 20px;
  color: #013B8D;
  background-color: #fff;
  font-family: 'Montserrat', sans-serif;
  padding: 10px 15px;
  font-weight: 500;
  font-size: 10pt;
  text-decoration: none;
}
.btn-right-slide-services-mobile:hover{
  background-color: transparent;
  border: 2px solid #fff;
  color: #fff;
}

.btn-right-slide-services{
  border: none;
  border-radius: 20px;
  color: #fff;
  background-color: #013B8D;
  font-family: 'Montserrat', sans-serif;
  padding: 10px 15px;
  font-weight: 300;
  text-decoration: none;
}

.btn-right-slide-services:hover{
  background-color: transparent;
  color: #013B8D;
  border: 2px solid #013B8D;
}

.btn-left-slide-services{
  border: none;
  border-radius: 20px;
  color: #013B8D;
  background-color: #fff;
  font-family: 'Montserrat', sans-serif;
  padding: 10px 15px;
  font-weight: 600;
  text-decoration: none;
}

.btn-left-slide-services:hover{
  border: 2px solid #fff;
  color: #fff;
  background-color: transparent;
}

.card-text-services{
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  color: #64625A;
}

.title2-bannerServices2{
  color: #B1904C;
  font-family: 'Poppins', sans-serif;
}

.img-cardServices{
  width: auto;
  max-height: 200px;
  height: 200px;
  object-fit: cover;
}

.title2-bannerServices2-mobile{
  font-family: 'Poppins', sans-serif;
  color: #fff;
  font-weight: 900;
}

.title-bannerServices2-mobile{
  color: #fff;
  font-weight: 400;
  font-size: 12pt !important;
  font-family: 'Montserrat', sans-serif;
  line-height: 2;
}

.btn-hero-services:hover{
  background-color: #013B8D;
  border: none;
  color: #fff;
}

.img-banner-fluid{
  height: 560px;
  width: 560px;
}

@media screen and (max-width:1250px){
  .img-banner-fluid{
    height: 400px;
    width: 400px;
  }

  .titleBannerServices2{
    font-size: 50pt;
  }
}

@media screen and (max-width: 991px){
    .hide-mobile{
      display: none !important;
    }
}

@media screen and (max-width: 975px){
  .titleBannerServices1{
    font-size: 22pt;
  }
  .titleBannerServices2{
    font-size: 40pt;
    width: auto;
  }
  .title-bannerServices2 h6{
    font-size: 18pt;
  }
  .title2-bannerServices2 h1{
    font-size: 16pt;
  }
  .img-banner-fluid{
    height: 370px;
    width: 370px;
  }
}

@media screen and (max-width: 835px){
  .img-banner-fluid{
    height: 320px;
    width: 320px;
  }
}

@media screen and (max-width: 768px){
  .titleBannerServices1{
    font-size: 20pt;
  }
  .titleBannerServices2{
    font-size: 30pt;
    width: auto;
  }
}

@media screen and (max-width: 690px){
  .img-banner-fluid{
    height: 290px;
    width: 290px;
  }
}

@media screen and (max-width: 590px){
  .img-banner-fluid{
    height: 230px;
    width: 230px;
  }
}

@media screen and (max-width: 468px){
  .titleBannerServices1{
    font-size: 12pt;
  }
  .titleBannerServices2{
    font-size: 20pt;
  }

  .card-title-services{
    font-size: 14pt;
  }

  .card-text-services{
    font-size: 10pt;
  }

  .btn-hero-services{
    font-size: 10pt;
  }

  .title-bannerServices2-mobile{
    font-size: 10pt !important;
  }

  .title2-bannerServices2-mobile{
    font-size: 16pt !important;
  }

  .btn-right-slide-services-mobile{
    font-size: 8pt !important;
  }

  .btn-right-slide-services{
    font-size: 8pt;
  }

  .title-bannerServices2 h6{
    font-size: 16pt;
  }
  .title2-bannerServices2 h1{
    font-size: 14pt;
  }
  .img-banner-fluid{
    max-width: 200px;
    max-height: 200px;
    height: auto;
    width: 100%;
  }
}