.hero{
  width: 100%;
  height: max-content;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;
  padding-top: 45px;
  padding-bottom: 45px;
}

.banner1{
  background-color: aliceblue;
  display: flex;
  flex-direction: column;
  background-position: top;
  align-items: center;
}

.overlay-banner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 0;
}

.video-style{
  object-fit: cover;
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
}

.title-banner1{
  font-family: sans-serif;
  color: #013B8D;
  font-size: 50px;
  font-weight: 600;
}

.subtitleBanner1{
  font-family: 'Montserrat', sans-serif;
  color: #FFD37A;
  font-size: 27px;
  font-weight: bold;
}

.MainTitle-banner3{
  color: #013B8D;
  font-size: 42px;
  font-weight: bolder;
}

.MainTitle-banner2{
  color: #FAFF00;
  font-size: 42px;
  font-weight: bolder;
}

.firstSideCard{
  padding: 0;
}
.secondSideCard{
  background-color: #013B8D;
  padding: 20px 20px;
  border-radius: 40px;
}

.secondSideCard .title-hero {
  color: white;
  font-size: 22pt;
}

.card-hero{
  margin-top: 50px;
  margin-bottom: 50px;
}

.title-box{
  text-align: left;
}

.round-left {
  border-top-left-radius: 78px;
  border-bottom-left-radius: 78px;
}

.round-right {
  border-top-right-radius: 78px;
  border-bottom-right-radius: 78px;
}
.title-hero{
  font-family: 'Montserrat', sans-serif;
}

.btn-hero{
  border: none;
  border-radius: 20px;
  color: #013B8D;
  background-color: white;
  font-family: 'Montserrat', sans-serif;
  padding: 15px;
  font-weight: 900;
  width: 300px;
  text-decoration: none;
}

.btn-hero:hover{
  border: 2px solid white;
  color: white;
  background-color: #013B8D;
}

.card-banner1{
  border-radius: 40px;
}

.special-title{
  font-weight: 600;
  font-size: 32pt;
  font-family: sans-serif;
}

/*=============Banner 2 ============*/
.btn-banner2{
  border: 2px solid #FAFF00;
  border-radius: 20px;
  color: #FAFF00;
  background-color: transparent;
  font-family: 'Montserrat', sans-serif;
  padding: 15px 30px;
  font-weight: 900;
  text-decoration: none;
}

.img-banner-2{
  width: 435px;
  height: 380px;
  background-size: cover;
  border-radius: 25px;
}

.banner2{
  background-image: url("https://onedrive.live.com/embed?resid=6F96C7D49D9BDA1A%21145&authkey=%21AK87gg1gKxvY9ng&width=1366&height=768");
}

.btn-banner2:hover{
  background-color: #FAFF00;
  color: #013B8D;
  font-weight: 900;
}

.text-banner2{
  font-family: 'Montserrat', sans-serif;
  color: white;
}

.img-opacity{
  opacity: 0.78;
}

/*=============Banner 3 ============*/

.title-banner3{
  color: #013B8D;
  font-weight: bolder;
  font-size: 18pt;
}

.btn-banner3{
  border: 2px solid #013B8D;
  background-color: white;
  color: #013B8D;
  border-radius: 55px;
  width: 250px;
  padding: 15px 80px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 900;
  text-decoration: none;
}

.btn-banner3:hover{
  color: white;
  border: 2px solid white;
  background-color: #013B8D;
}

.img-banner3{
  height: 500px;
  width: auto;
  border-radius: 25px;
}

.body-banner3{
  color: #013B8D;
  font-family: 'Montserrat', sans-serif;
  font-size: 13pt;
  font-weight: 600;
}

/*Media Query */

@media screen and (max-width: 768px){
  .card-banner1{
    max-width: 500px !important;
  }
  .video-style{
    border-radius: 40px;
    min-height: 200px !important;
  }
  .round-left{
    border-radius: 78px;
  }
  .firstSideCard{
    border-top-left-radius: 78px;
    border-top-right-radius: 78px;
    background-color: #013B8D;
  }

  .round-right{
    border-top-right-radius: 0;
    border-bottom-left-radius: 78px;
  }

  .btn-hero{
    padding: 12px;
    width: 250px;
  }

  .imgBanner2{
    height: 200px;
    width: 100%;
    border-radius: 78px;
    object-fit: cover;
  }

  .MainTitle-banner2{
    font-size: 47px;
  }

  .special-title{
    font-size: 30pt;
  }

}

@media screen and (max-width: 468px){
  .video-style{
    min-height: 220px !important;
  }

  .secondSideCard h4{
    font-size: 16pt;
  }
  .text-banner2{
    font-size: 12pt !important;
  }
  
  .btn-banner2{
    height: 50px;
    width: 200px;
  }

  .btn-banner3{
    font-size: 10pt;
  }

  .btn-hero{
    padding: 10px;
    width: auto;
    font-size: 8pt;
  }

  .subtitleBanner1{
    font-size: 20px;
  }

  .imgBanner2{
    height: 200px;
    width: 100%;
    border-radius: 78px;
  }

  .MainTitle-banner2{
    font-size: 37px;
  }

  .special-title{
    font-size: 25pt;
  }

  .title-banner3{
    font-size: 15pt;
  }

  .body-banner3{
    font-size: 10pt;
  }
}

@media screen and (max-width: 320px) {
  .imgBanner2{
    height: 150px;
    width: 100%;
    border-radius: 78px;
  }

  .MainTitle-banner2{
    font-size: 32px;
  }
}
