.text-aboutUs{
  font-family: 'Montserrat', sans-serif;
  color: #013B8D;
  font-size: 16pt;
  font-weight: 900;
  line-height: 2;
}

.title-AboutUs{
  font-size: 30pt;
}

.img-banner-about{
  max-height: 870px;
}

@media screen and (max-width: 1200px){
  .text-aboutUs{
    font-size: 14pt;
  }

  .title-AboutUs{
    font-size: 25pt;
  }

  .img-banner-about{
    max-height: 800px;
  }
}

@media screen and (max-width: 786px){
  .text-aboutUs{
    font-size: 12pt;
  }

  .title-AboutUs{
    font-size: 22pt;
  }
}

@media screen and (max-width: 486px){
  .text-aboutUs{
    font-size: 10pt;
  }

  .title-AboutUs{
    font-size: 18pt;
  }
}