.background-form{
  background-image: url("https://onedrive.live.com/embed?resid=6F96C7D49D9BDA1A%21145&authkey=%21AK87gg1gKxvY9ng&width=1366&height=768");
}

.card-connect{
  background-color: #fff;
  border-radius: 50px;
}

.title-form{
  font-size: 30pt;
  color: #013B8D;
  font-family: 'Montserrat', sans-serif;
  font-weight: bolder;
}

.subtitle-form{
  font-family: 'Montserrat', sans-serif;
  color: #000;
  line-height: 1.8;
  font-size: 12pt;
}

.form-control{
  margin: 10px 0;
  border: none;
  border-bottom: 1px solid #000;
  border-radius: 0;
}

.btn-connect{
  border: none;
  border-radius: 14px;
  color: #fff;
  font-weight: bolder;
  background-color: #013B8D;
  font-family: 'Montserrat', sans-serif;
}

.btn-connect:hover{
  border: 2px solid #013B8D;
  color: #013B8D;
  background-color: #fff;
  font-weight: bolder;
}

.info-Connect{
  font-family: 'Montserrat', sans-serif;
  font-size: 10pt;
}

.info-Connect a{
  text-decoration: none;
}

.info-Connect a:hover{
  font-weight: bolder;
}

p{
  word-wrap: break-word;
}

.title-info{
  font-size: 13pt;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
}

@media screen and (max-width: 468px){
  .title-form{
    font-size: 22pt;
  }
  .subtitle-form{
    font-size: 10pt;
    color: #424242;
  }

  .form-control{
    font-size: 10pt !important;
  }

  .btn-connect{
    font-size: 8pt;
  }

  .title-info{
    font-size: 10pt;
  }

  .info-Connect{
    font-size: 8pt;
  }
}